.category-boxes {
  position: relative;
  padding: 0.5rem 0 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
}
.category-box {
  min-width: max-content;
  padding: 0.5rem 1rem;
  background-color: var(--light-bg);
  color: var(--gray-color-200);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.25s;
}
.category-box:hover,
.category-box.active {
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
}
