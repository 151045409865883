.header {
  position: fixed;
  width: 100%;
  height: var(--header-height);
  background-color: var(--main-bg);
  border-bottom: 1px solid var(--light-bg);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  padding: 0.25rem 0;
  z-index: 9;
}
.header-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex-direction: row;
}
.logo-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.logo-area img {
  width: 30px;
  object-fit: cover;
}
.logo-area span {
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--dark-color);
}
.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
}
.links .link-item {
  padding: 0.25rem 0.5rem;
  color: var(--dark-color);
  transition: all 0.25s;
  cursor: pointer;
}
.links .link-item:hover,
.links .link-item.active {
  color: var(--first-color);
}
.links i {
  padding-right: 0.25rem;
}
.link-item.menu-link {
  font-size: 1.75rem;
  display: none;
}
.bag-link,
.theme-btn {
  position: relative;
  display: grid;
  place-items: center;
  width: 38px;
  height: 38px;
  background-color: var(--opacity-bg);
  border-radius: 50%;
}
.theme-btn i,
.bag-link i {
  font-size: 0.9rem;
  padding-right: 0;
  color: var(--first-color);
}
.theme-btn {
  background-color: var(--light-bg);
}
.theme-btn i {
  color: var(--gray-color-200);
}
.bag-count {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: var(--first-color);
  display: grid;
  place-items: center;
  color: var(--light-color);
  border-radius: 50%;
  font-size: 0.55rem;
}
@media screen and (max-width: 768px) {
  .links.navbar {
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 0;
    height: calc(100vh - var(--header-height));
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    background-color: var(--main-bg);
    transition: all 0.25s ease-in-out;
    z-index: 99;
  }
  .links.navbar.open {
    width: 100%;
  }
  .links.navbar a {
    padding: 0.75rem 3rem;
    border-bottom: 2px solid var(--first-color);
    border-top: 2px solid var(--first-color);
  }
  .links.navbar a:hover,
  .links.navbar a.active {
    background-color: var(--first-color);
    color: #fff;
    border-radius: 0.1rem 1rem;
  }
  .links {
    gap: 0.75rem;
  }
  .link-item.menu-link {
    display: flex;
  }
}
@media screen and (max-width: 510px) {
  .logo-area span {
    display: none;
  }
  .links {
    gap: 0.5rem;
  }
}
