.cart-area {
  min-height: 65vh;
}
.empty-message {
  width: 100%;
  padding: 1.25rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  background-color: var(--opacity-bg);
  border: 1px solid var(--first-color);
  color: var(--first-color);
}
.cart-item {
  padding: 1rem 0;
  border-bottom: 1px solid var(--light-bg);
  display: grid;
  grid-template-columns: calc(100% - 236px) 220px;
  align-items: center;
  gap: 1rem;
}
.cart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.cart-row:nth-child(2) {
  justify-content: space-between;
  gap: 0.25rem;
}
.cart-image {
  background-color: var(--light-bg);
  border-radius: 1rem;
}
.cart-image img {
  width: 75px;
  object-fit: cover;
}
.cart-texts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.cart-title,
.cart-price {
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark-color);
}
.cart-explain {
  font-size: 0.8rem;
  color: var(--gray-color-200);
  width: 75%;
}
.cart-title,
.cart-explain {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cart-action {
  width: 100px;
  padding: 0.5rem;
}
.cart-remove {
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #fef2f2;
  font-size: 0.9rem;
  cursor: pointer;
  color: #dc2626;
}
body.dark-mode .cart-remove {
  color: #ff4555;
  background-color: #43202e;
}
.cart-total {
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.5rem;
  border-bottom: 1px solid var(--light-bg);
}
.total-text {
  font-size: 0.8rem;
  color: var(--gray-color-200);
}
.total-text b {
  color: var(--dark-color);
}
.order-btn-area {
  padding: 2.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.order-button {
  position: relative;
  width: 350px;
  max-width: 100%;
  padding: 1rem;
  background-color: var(--first-color);
  color: #fff;
  text-align: center;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .cart-item {
    grid-template-columns: repeat(1, 1fr);
  }
  .cart-explain {
    width: 100%;
  }
  .cart-image img {
    width: 65px;
  }
}
